.hideHeader .ant-picker-calendar-header {
    display: none;
}

.bottomContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.bottomBox {
    margin: 1rem 3.5rem 0.5rem 3.5rem;
    padding: 1rem;
    background: #141414;
    border-radius: 0.2rem;
}