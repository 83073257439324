.text {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.regelnHeader {
    padding-left: 2rem;
}

.foreground {
    z-index: 2;
    position: relative;
}

.funnyImage {
    position: absolute;
    right: 0;
    top: 37rem;
    z-index: 1;
}